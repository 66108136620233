export const defaultTask = [
  {
    title: "Todo",
    id: "C100000",
    tasks: [],
  },
  {
    title: "In-Progress",
    id: "C100001",
    tasks: [],
  },
  {
    title: "Complete",
    id: "C100002",
    tasks: [],
  },
];
